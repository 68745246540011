<template>
    <div class="app-sidebar-details">
        <div class="sidebar-header">
            <span class="title">{{ title }}</span>
            <button class="close" @click="closeSidebar"></button>
        </div>

        <div class="sidebar-body" ref="body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        title: { type: String, default: 'Details' },
    },

    methods: {
        closeSidebar() {
            this.$emit('close-sidebar')
        },
    },
}
</script>

<style lang="scss">
.app-sidebar-details {
    height: 100%;

    .sidebar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 96px;
        padding-left: 32px;
        padding-right: 32px;
        border-bottom: 1px solid var(--color-sidebar-divider);

        .title {
            font-size: 18px;
            line-height: 1.78;
            color: var(--color-sidebar-text);
        }

        .close {
            width: 24px;
            height: 24px;

            color: var(--color-icon-calm);

            @include icon-before($icon-close);

            cursor: pointer;

            transition: $transition-duration-primary;

            &:hover {
                color: var(--color-icon-action);
            }
        }
    }

    .sidebar-body {
        $scrollbar-width: 6px;

        height: calc(100% - 96px);

        overflow-y: auto;

        @include webkit-scrollbar-vertical(
            none,
            var(--color-nav-bar-scrollbar-bg),
            var(--color-nav-bar-scrollbar-thumb-bg),
            $scrollbar-width
        );

        /*
            &::-webkit-scrollbar {
                width: 0 !important;
            }
        */

        .app-loader {
            img {
                width: 48px;
                height: 48px;
            }
        }

        .app-error {
            margin-top: 24px;
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-sidebar-details {
        .sidebar-header {
            height: 48px;
            padding: 12px $container-padding-mobile;

            .title {
                font-size: 16px;
                line-height: 24px;
            }
        }

        .sidebar-body {
            height: calc(100% - 48px);
            padding: 30px 0 64px;
        }
    }
}
</style>