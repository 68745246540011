<template>
    <div class="app-table-details">
        <div v-if="title || editable" class="heading-box">
            <h2 v-if="title" class="heading">{{ title }}</h2>

            <button v-if="editable" class="btn btn-table-action action-edit action-primary" @click="edit"></button>
        </div>

        <app-loader v-if="loading"></app-loader>

        <ul class="details">
            <template v-for="(row, key) in rows">
                <li :key="key" v-if="data[ row.key ] || !row.optional">
                    <div>{{ row.title }}</div>

                    <div v-if="row.type == 'color'">
                        <span class="color" v-if="data[ row.key ]">
                            <i :style="{ background: data[ row.key ] }"></i>
                        </span>
                        <span v-else>?</span>
                    </div>

                    <div v-else-if="row.type == 'datenanos'">
                        {{formatDateNano(data[row.key])}}
                    </div>

                    <div v-else-if="row.type == 'yesno'">
                        {{data[row.key] ? 'Yes' : 'No'}}
                    </div>

                    <div v-else-if="row.type == 'status'">
                        <span class="status" :class="data[row.key].status">{{ data[row.key].text }}</span>
                    </div>

                    <div v-else-if="row.type == 'code'">
                        <app-code :code="data[row.key].code" :collapsed="true" :title="data[row.key].title" />
                    </div>

                    <div v-else>
                        <span>{{ data[ row.key ] }}</span>
                        <button class="btn btn-table-action action-edit action-primary btn-edit" @click="editRow(row)" v-if="row.editable"></button>
                    </div>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appCode from '@/components/app-code'

import formatDateNano from '@/helpers/format-date-nano'

/**
 * @todo
 * Remove data type "datenanos"
 */

export default {
    props: {
        rows:    { type: Array, required: true },
        data:    { type: Object, required: true },

        title:   { type: String, default: '' },

        editable: { type: Object },

        loading: { type: Boolean, default: false },
    },

    components: {
        appLoader,
        appCode,
    },

    data() {
        return {
            is_mobile: false,
        }
    },

    mounted() {
        this.handleWindowResize()

        window.addEventListener('resize', this.handleWindowResize)
    },

    methods: {
        formatDateNano,

        handleWindowResize() {
            this.is_mobile = document.body.clientWidth <= this.$mobile_size
        },

        edit() {
            if (this.is_mobile) {
                if (this.editable.mobile) {
                    this.$emit(this.editable.mobile)
                } else {
                    this.$emit(this.editable.action)
                }
            } else {
                this.$emit(this.editable.action)
            }
        },

        editRow(row) {
            this.$emit('edit', row.key)
        },
    },

    computed: {
        ...mapGetters([
            '$mobile_size',
        ]),
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize)
    },
}
</script>

<style lang="scss">
.app-table-details {
    width: 100%;
    padding: 24px 24px 0;
    background-color: var(--color-table-row-bg);
    border-radius: $border-radius-primary;
    box-shadow: var(--box-shadow-secondary);
    position: relative;

    .heading-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 8px;

        .btn {
            margin-left: 24px;
        }
    }

    .app-loader {
        svg {
            width: 48px;
            height: 48px;
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        min-height: 16px;

        li {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            // align-items: center;
            color: var(--color-text-default);
            font-size: 16px;
            line-height: 24px;
            padding: 16px 0;
            border-bottom: 1px solid var(--color-divider);

            &:last-child {
                border-bottom: none;
            }

            &>:nth-child(1) {
                flex-grow: 1;
                flex-shrink: 0;
                color: var(--color-personal-info-primary);
                margin-right: 4px;
            }

            &>:nth-child(2) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-width: 0;
                color: var(--color-personal-info-secondary);
                text-align: right;
                word-break: break-all;

                .btn-edit {
                    margin-left: 12px;
                    width: 16px;
                    height: 16px;
                    position: relative;

                    &:before {
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .color {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 24px;
                    height: 24px;
                    padding: 2px;
                    border: 2px solid var(--color-divider);
                    border-radius: 50%;

                    i {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }

                .status {
                    padding: 4px 12px;

                    font-size: 16px;
                    font-weight: bold;
                    line-height: 24px;

                    text-align: center;

                    border-radius: $border-radius-primary;

                    white-space: nowrap;

                    @each $type in $table-tag-types {
                        &.#{ $type } {
                            color: map-get($table-tag-colors, $type);
                            background-color: map-get($table-tag-backgrounds, $type);
                        }
                    }
                }

                & > .app-code {
                    max-width: 540px;
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .app-table-details {
        padding: 24px 8px 0;

        .heading-box {
            padding: 0 8px;
        }

        .details {
            li {
                font-size: 14px;

                &>:nth-child(2) {
                    .btn-edit {
                        margin-left: 12px;

                        &:before {
                            width: 20px;
                            height: 20px;
                            font-size: 20px;
                        }
                    }

                    .status {
                        padding: 2px 8px;

                        font-size: 12px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-table-details {
        padding: 16px 8px 4px;

        .heading-box {
            padding: 0;
        }

        .details {
            li {
                padding: 8px 0;
                white-space: nowrap;
                flex-wrap: wrap;

                &>div {
                    width: 100%;
                    flex-grow: 1;
                    margin: 0;
                }

                &>:nth-child(2) {
                    .btn-edit {
                        margin-left: 8px;
                    }

                    .status {
                        line-height: 20px;
                    }
                }
            }
        }
    }
}
</style>