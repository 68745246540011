<template>
    <div class="app-pagination">

        <div class="shadow">
            <button
                class="prev"
                :disabled="disabled || !hasPrev"
                @click="changePage(prevPage)"
            >
                <span class="direction">Prev</span>
            </button>

            <div class="pages">
                <template v-for="(page, index) in pages">
                    <button
                        class="number"
                        v-if="typeof(page) == 'number'"
                        @click="changePage(page)"
                        :class="{ current: current === page }"
                        :key="index"
                        :disabled="disabled"
                    >{{ page }}</button>

                    <div v-else class="dots" :key="index">&hellip;</div>
                </template>
            </div>

            <button
                class="next"
                :disabled="disabled || !hasNext"
                @click="changePage(nextPage)"
            >
                <span class="direction">Next</span>
            </button>
        </div>


    </div>
</template>

<script>
import { mapGetters } from 'vuex'

const default_limit = 9

export default {
    props: {
        value:     { type: Number, required: true },
        total:     { type: Number, default: 0 },
        limit:     { type: Number, default: default_limit },
        pageRange: { type: Number, default: 2 },
        dots:      { type: Number, default: 1 },
        disabled:  { default: false },

        nextButtonEnabled: { type: Boolean, default: false },
    },

    data() {
        return {
            range: 0
        }
    },

    methods: {
        changePage: function (page) {
            this.$emit('input', page)
            this.$emit('change', page)
        },

        handleWindowResize() {
            const $el_width = this.$el.offsetWidth

            let range = this.pageRange

            const prev_button = 1
            const first = 1
            const left_dots = 1
            const left_range = this.pageRange
            const current = 1
            const right_range = this.pageRange
            const right_dots = 1
            const last = 1
            const next_button = 1

            let button_width = 32
            let prev_button_width = 96
            let next_button_width = 96
            let margin = 4



            if (document.body.clientWidth <= this.$mobile_size) {
                button_width = 24
                prev_button_width = 64 + 5 // + 5px margin
                next_button_width = 64 + 5 // + 5px margin
                margin = 0
            }

            

            const buttons = prev_button
                                        + first
                                        + left_dots
                                        + left_range
                                        + current
                                        + right_range
                                        + right_dots
                                        + last
                                        + next_button

            let width = prev_button_width
                                        + ( first + left_dots + left_range + current + right_range + right_dots + last ) * button_width
                                        + next_button_width
                                        + ( buttons - 1 ) * margin

            while(width >= $el_width) {
                range -= 1
                width = prev_button_width
                                        + ( first + left_dots + range + current + range + right_dots + last ) * button_width
                                        + next_button_width
                                        + ( buttons - 1 ) * margin
            }

            this.range = Math.max(0, range)
        }
    },

    computed: {
        ...mapGetters([
            '$mobile_size',
        ]),

        totalPages: function () {
            return Math.ceil(this.total / (this.limit ? Math.abs(this.limit) : default_limit))
        },
        current() {
            return this.value
        },

        hasPrev: function () {
            return this.current > 1
        },
        hasNext: function () {
            return this.current < this.totalPages || this.nextButtonEnabled
        },

        prevPage: function () {
            return this.current - 1
        },
        nextPage: function () {
            return this.current + 1
        },

        pages() {
            const total_pages = this.totalPages
            const current_page = this.current

            let pages = []

            const first = 1
            const left_dots = 1
            const left_range = this.range
            const current = 1
            const right_range = this.range
            const right_dots = 1
            const last = 1

            const minimum = first
                                + left_dots
                                + left_range
                                + current
                                + right_range
                                + right_dots
                                + last

            let pages_list = []

            for (let i = 1; i <= total_pages; i++) {
                pages_list.push(i)
            }

            if (total_pages < minimum) {
                pages = pages_list
            } else {
                let current_index = current_page - 1
                let start = current_index - first - left_dots - left_range
                let end = current_index + right_range + right_dots + last

                if (start < 0) {
                    const diff = Math.abs(start)
                    start += diff
                    end += diff
                }

                if (total_pages < end) {
                    const diff = Math.abs(total_pages - end) + 1
                    start -= diff
                    end -= diff
                }

                if (end > total_pages - 1) {
                    end = total_pages - 1
                    start -= 1
                }

                for (let i = start; i <= end; i++) {
                    pages.push(pages_list[i])
                }

                const first_page = 1
                let first_page_index = 0

                const last_page = total_pages
                let last_page_index = 0

                first_page_index = pages.indexOf(first_page)
                last_page_index = pages.indexOf(last_page)

                /**
                 * Add left dots, if it necessary
                 */
                if (first_page_index == -1) {
                    pages[0] = first_page
                    pages[1] = '...'
                } else {
                    if ((current_index - first_page_index) >= ( first + left_dots + left_range )) {
                        pages[0] = first_page
                        pages[1] = '...'
                    }
                }

                /**
                 * Add right dots, if it necessary
                 */
                if (last_page_index == -1) {
                    pages[pages.length - 1] = last_page
                    pages[pages.length - 2] = '...'
                } else {
                    if ((last_page_index - current_index) >= ( right_range + right_dots + last )) {
                        pages[pages.length - 1] = last_page
                        pages[pages.length - 2] = '...'
                    }
                }
            }

            return pages
        },
    },

    mounted() {
        this.handleWindowResize()

        window.addEventListener('resize', this.handleWindowResize)
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize)
    }
}
</script>

<style lang="scss">
.app-pagination {
    user-select: none;

    .shadow {
        display: flex;
        width: fit-content;
        height: 48px;
        background-color: var(--color-pagination-page-bg);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-tertiary);
    }

    .prev,
    .next {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 96px;
        font-size: 18px;
        line-height: 24px;
        color: var(--color-pagination-page);
        cursor: pointer;

        .direction {
            position: relative;
            height: 24px;

            &::after {
                position: absolute;
                top: 1px;
                display: block;

                width: 24px;
                height: 24px;

                color: var(--color-pagination-page);
            }
        }

        &:hover {
            color: var(--color-pagination-page-hover);
            background: var(--color-pagination-page-bg-hover);

            .direction {
                &::after {
                    color: var(--color-pagination-page-hover);
                }
            }
        }

        &:active {
            color: var(--color-pagination-page-active);
            background: var(--color-pagination-page-bg-active);

            .direction {
                &::after {
                    color: var(--color-pagination-page-active);
                }
            }
        }

        &:disabled {
            pointer-events: none;

            .direction {
                opacity: 0.25;
            }
        }
    }

    .prev {
        border-right: 1px solid var(--color-pagination-border);
        border-radius: $border-radius-primary 0 0 $border-radius-primary;

        .direction {
            padding-left: 16px;

            &::after {
                left: -9px;

                @include icon($icon-chevron-left);
            }
        }
    }

    .next {
        border-left: 1px solid var(--color-pagination-border);
        border-radius: 0 $border-radius-primary $border-radius-primary 0;

        .direction {
            padding-right: 16px;

            &::after {
                right: -9px;

                @include icon($icon-chevron-right);
            }
        }
    }

    .pages {
        display: flex;
        align-items: center;

        .number {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            font-size: 18px;
            line-height: 1.33;
            color: var(--color-pagination-page);
            background: var(--color-pagination-page-bg);
            border-radius: $btn-border-radius-secondary;
            cursor: pointer;

            &:hover {
                color: var(--color-pagination-page-hover);
                background-color: var(--color-pagination-page-bg-hover);
            }

            &.current {
                color: var(--color-pagination-page-active);
                background-color: var(--color-pagination-page-bg-active);
            }

            &[disabled] {
                opacity: .4;
                cursor: default;
                pointer-events: none;
            }
        }

        .dots {
            display: flex;
            justify-content: center;
            width: 32px;
            font-size: 18px;
            line-height: 1.33;
            color: var(--color-pagination-page);
            cursor: default;
        }

        .number,
        .dots {
            margin-left: 4px;

            &:last-child {
                margin-right: 4px;
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-pagination {
        .prev,
        .next {
            font-size: 14px;
            width: 64px;
        }

        .prev {
            text-align: left;
            padding-left: 6px;
        }
        .next {
            text-align: right;
            padding-right: 6px;
        }

        .pages {
            padding: 0 5px;

            .number,
            .dots {
                widtH: 24px;
                height: 24px;
                font-size: 14px;
                margin: 0;

                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}
</style>