<template>
    <div class="app-adding-banner" :class="{ 'tablet-mode': tabletMode }">
        <div class="banner-body">
            <h2 class="heading">{{ header }}</h2>
            <p class="banner-description" v-if="description">{{ description }}</p>
            
            <div class="banner-img">
                <component :is="image_component" />
            </div>
            
            <button           v-if="action" class="btn btn-secondary" @click="onActionClick">{{ actionTitle }}</button>
            <router-link v-else-if="route"  class="btn btn-secondary" :to="route"           >{{ actionTitle }}</router-link>
        </div>

        <div class="banner-img">
            <component :is="image_component" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        image:       { type: String, required: true      }, // service | member | credit-card | team | team-member | api-key
        header:      { type: String, default: 'Add'      },
        description: { type: String, default: null       },

        tabletMode:  { type: Boolean, default: false     },

        route:       { type: Object, default: () => null },
        action:      { type: String, default: null       },
        actionTitle: { type: String, default: 'Add'      },
    },

    data() {
        return {
            images: {},
        }
    },

    methods: {
        onActionClick() {
            this.$emit(this.action)
        },
    },

    computed: {
        image_component() {
            let image_component = null

            if (this.image in this.images) {
                image_component = this.images[ this.image ]
            } else {
                const image = this.image

                import(`./images/${ image }`).then(component => {
                    this.$set(this.images, image, component.default)
                }).catch(error => {
                    this.$set(this.images, image, null)
                })
            }

            return image_component
        },
    },
}
</script>

<style lang="scss">
.app-adding-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 48px;
    border-radius: $border-radius-secondary;
    background-color: var(--color-component-bg-primary);
    box-shadow: var(--box-shadow-secondary);

    .banner-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2.heading {
            text-align: center;
            margin-bottom: 16px;
        }

        .banner-description {
            width: 100%;
            max-width: 305px;
            margin-bottom: 24px;
            font-size: 18px;
            line-height: 1.33;
            text-align: center;
            color: var(--color-text-secondary);
        }

        .banner-img {
            display: block;
            width: 100%;
            height: auto;
            margin-bottom: 24px;
        }
    }

    &>.banner-img {
        display: none;
    }

    &.tablet-mode {
        max-width: 690px;

        flex-direction: row;
        justify-content: space-between;
        padding: 24px;

        .banner-body {
            flex-grow: 1;
            align-items: flex-start;

            .banner-description {
                text-align: left;
                max-width: none;
                margin-bottom: 48px;
            }

            .banner-img {
                display: none;
            }
        }

        &>.banner-img {
            flex-shrink: 0;
            width: 47.75%;
            max-width: 310px;
            display: initial;
            margin: 0 0 0 16px;
        }
    }
}

@media (max-width: $tablet-size) {
    .app-adding-banner {
        flex-direction: row;
        justify-content: space-between;
        padding: 24px;

        .banner-body {
            flex-grow: 1;
            align-items: flex-start;

            .banner-description {
                text-align: left;
                max-width: none;
                margin-bottom: 48px;
            }

            .banner-img {
                display: none;
            }
        }

        &>.banner-img {
            flex-shrink: 0;
            width: 47.75%;
            max-width: 310px;
            display: initial;
            margin: 0 0 0 16px;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-adding-banner {
        flex-direction: column;
        justify-content: center;
        text-align: center;

        .banner-body {
            align-items: center;

            h2.heading {
                width: 100%;
            }

            .banner-description {
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                margin-bottom: 16px;
            }

            .banner-img {
                display: initial;
                margin-bottom: 16px;
            }
        }

        &>.banner-img {
            display: none;
        }
    }
}
</style>