<template>
    <div class="base-layout">
        <slot @open-sidebar="onOpenSidebar"></slot>

        <div class="sidebar">
            <slot name="sidebar" @close-sidebar="onCloseSidebar"></slot>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        onOpenSidebar() {
            this.$emit('open-sidebar')
        },

        onCloseSidebar() {
            this.$emit('close-sidebar')
        },
    },
}
</script>

<style lang="scss">
.base-layout {
    padding-bottom: 80px;

    .sidebar {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: $z-index-sidebar;

        width: $sidebar-width;
        max-width: 100%;

        transform: translateX($sidebar-width);
        transition: transform $transition-duration-primary;

        background-color: var(--color-sidebar-bg);

        overflow: hidden;

        .app-customer-event-log {
            padding-bottom: 32px;
            box-shadow: none;
            border-radius: $border-radius-primary $border-radius-primary 0 0;

            .heading {
                font-size: 24px;
                line-height: 32px;
            }

            .app-textarea {
                height: 144px;
            }
        }

        .app-comments {
            padding-top: 0;
            box-shadow: none;
            border-radius: 0 0 $border-radius-primary $border-radius-primary;

            .header {
                border-radius: 4px;
                background-color: var(--color-sidebar-search-bg);
                padding: 12px 24px;
                height: auto;

                .heading {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: normal;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.sidebar-opened {
    .base-layout {
        .sidebar {
            transform: translateX(0px);
        }
    }
}

@media (max-width: $tablet-size) {
    .base-layout {
        .sidebar {
            width: $sidebar-width-tablet;

            transform: translateX($sidebar-width-tablet);

            .app-comments {
                .header {
                    padding: 12px 16px;

                    .heading {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .base-layout {
        padding-bottom: 64px;
    }
}
</style>