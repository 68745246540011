<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details title="Service Provider Details" @close-sidebar="closeSidebar">
                <sidebar-details :uuid="UUID" @close-sidebar="closeSidebar"></sidebar-details>
            </app-sidebar-details>
        </template>

        <div class="page-matrixx-sp-groups container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Customers</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-8 col-tab-12">
                    <app-table
                        class="matrixx-customers-table"

                        :cols="cols"
                        :rows="customers"

                        :clickable="true"
                        :selected-row="UUID"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onSortChange"

                        @row-click="openDetails"
                        @row-click-mobile="openDetails"
                    />

                    <app-pagination
                        v-if="pagination.total"

                        v-model="pagination.page"

                        :total="pagination.total"
                        :limit="pagination.limit"
                        :page-range="pagination.range"

                        @change="onPageChange"
                    />
                </div>
                
                <div class="col-4 col-tab-12">
                    <app-adding-banner 
                        header="Add Customers" 
                        description="Easily add a customer within Matrixx"
                        :route="{name: 'matrixx-add-customer-group'}"
                        action-title="Add"
                        image="service"
                    />
                </div>
            </div>
        </div>

        <app-loader v-if="loading"></app-loader>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'

import appSidebarDetails from '@/components/app-sidebar-details'
import sidebarDetails from '@/views/matrixx/sp-group-details'

import appAddingBanner from '@/components/app-adding-banner'

import appLoader from '@/components/app-loader'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

export default {
    components: {
        baseLayout,

        appSidebarDetails,
        sidebarDetails,

        appAddingBanner,

        appLoader,
        appTable,
        appPagination,
    },

    data() {
        return {
            UUID: '', // selected row

            customers: [],

            filter: {
                word: '',
                previous: '',
                sort: {
                    field: 'CreatedAtNanos',
                    order: 'desc'
                },
            },

            sort: [
                { field: 'Name', title: 'Name'       },
                { field: 'CreatedAtNanos', title: 'Created at' },
            ],

            cols: [
                { key: 'Name',        title: 'Name',         sort: { field: 'Name'   }, highlight: true, },
                { key: 'CreatedAtNanos',  title: 'Created at',   sort: { field: 'CreatedAtNanos' }, type: 'datenanos'},
                { key: 'UUID',        title: 'UUID',         sort: { field: 'UUID'   }, highlight: true, },
                { key: 'MtxObjectID',        title: 'MtxObjectID',         sort: { field: 'MtxObjectID'   }, highlight: true, },
            ],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            loading: false,
        }
    },

    methods: {
        init(to, from) {
            this.getServiceProviderGroups(to.params.page)
        },

        getServiceProviderGroups(page, filter) {
            this.loading = true

            filter = {
                ...this.filter,
                ...this.search,
                ...filter
            }
            console.warn('filter', {...filter})

            let params = {
                "SPID": this.current_spid,
                'MtxType': 'MvnoCustomerGroupExtension',
                'IncludeInactive': true,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize': this.pagination.limit,
                'SearchOptions.SortBy': filter.sort.field,
                'SearchOptions.SortDesc': filter.sort.order === 'desc' ? 1 : 0,
            }
            console.warn("SPID", params.SPID)

            const word = filter.word.trim()
            console.log('word', word)

            if (word.length > 0) {
                params = {
                    ...params,
                    'SmartSearch': word,
                    'SearchOptions.PageNumber': word !== filter.previous ? 1 : params['SearchOptions.PageNumber']
                }

                this.filter.word = word
                this.filter.previous = word
            }

            this.$store.dispatch('api_matrixx/GetMtxGroupsPaginated', params).then(response => {
                console.log('resp', response)
                this.customers = response.MtxGroups

                const { DisplayPageList, PageNumber, TotalItemCount } = response.PageInfo
                console.log('DisplayPageList',DisplayPageList,'PageNumber', PageNumber, 'TotalItemCount', TotalItemCount)

                this.pagination.page = PageNumber
                this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                if (this.$route.params.page != this.pagination.page
                    && !(
                        this.$route.params.page === undefined
                        && this.pagination.page === 1
                    )
                ) {
                    this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                }

                this.loading = false
            }).catch(error => {
                console.error(error)

                this.customers = []

                this.loading = false
            })
        },


        /**
         * Filtering
         */
        onSearch(search) {
            if (search.word.trim() != this.filter.word.trim()) {
                this.getServiceProviderGroups(this.pagination.page, { word: search.word })
            }
        },

        onSortChange(sort) {
            this.filter.sort = sort
            this.getServiceProviderGroups()
        },

        onPageChange(page) {
            this.getServiceProviderGroups(page)
        },

        /**
         * Service Details
         */
        openDetails(row) {
            this.$router.push({ name: 'matrixx-customer-group-details', params: { uuid: row.UUID } })
        },

        showDetails(row) {
            if (this.UUID == row.UUID) {
                this.closeSidebar()
            } else {
                this.UUID = row.UUID
                this.openSidebar()
            }
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.UUID = null
            this.$emit('close-sidebar')
        },
    },

    computed: {
        ...mapGetters([
            'current_sp',
            'current_spid',

            'is_super_admin',
            'current_sp_roles',
            'available_service_providers',
        ])
    },
    
    beforeRouteEnter(to, from, next) {
        next(route => { route.init( to, from ) })
    },

    beforeRouteLeave(to, from, next) {
        this.closeSidebar()
        next()
    },
}
</script>

<style lang="scss">
.page-matrixx-sp-groups {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .matrixx-customers-table {
        @include table-cols-width((200px, 120px, 250px, 85px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-matrixx-sp-groups {
        .matrixx-customers-table {
            margin-bottom: 30px;

            @include table-cols-width((150px, 100px, 150px, 85px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-matrixx-sp-groups {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .matrixx-customers-table {
            margin-bottom: 15px;

            @include table-cols-width-mobile((85px, 190px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>